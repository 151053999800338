import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Breadcrumbs from "@components/Breadcrumbs";
import Opinion from "@components/Opinion";
import Button from "@components/Button";

import GoogleLogo from "@icons/google.svg";
import BooksyLogo from "@icons/booksy.svg";
import FacebookLogo from "@icons/facebook-logo.svg";
import StarIcon from "@icons/star.svg";
import HalfStarIcon from "@icons/half-star.svg";

function OpinionPage({ data, pageContext }) {
  const { slug, title } = pageContext;
  const { t } = useLocale();

  const breadcrumbs_data = [
    { name: t("Opinie klientów"), href: `/opinie` },
    { name: title, href: `/${slug}` },
  ];
  const {
    opinionAuthor: author,
    opinionRate: rate,
    opinionDescription: description,
    opinionScreen: screen,
    opinionOrigin: origin,
  } = data.wpOpinion.opinion;
  const photo = getImage(screen?.gatsbyImage);
  const other_opinions = data.other_opinions.nodes;

  const getLogoService = (name) => {
    switch (name) {
      case "Google":
        return <GoogleLogo width={90} height={30} />;
      case "Booksy":
        return <BooksyLogo width={140} height={30} />;
      case "Facebook":
        return <FacebookLogo width={140} height={30} />;
      default:
        return null;
    }
  };

  const getRateStars = (number) => {
    const stars_list = [];

    for (let i = 1; i <= number; i++) {
      stars_list.push(<StarIcon />);
    }

    if (number % 5) stars_list.push(<HalfStarIcon />);

    return stars_list;
  };

  return (
    <div className="opinion-page">
      <div className="container">
        <Breadcrumbs data={breadcrumbs_data} />
        <main>
          <div className="opinion-page__photo">
            {!!photo && <GatsbyImage image={photo} alt={`Opina z ${origin}`} />}
          </div>

          <div className="opinion-page__info">
            <h5>{t("Opinia:")}</h5>
            {getLogoService(origin)}
            <h5>{t("Ocena:")}</h5>
            <div className="opinion-page__stars">{getRateStars(rate)}</div>

            <h2>{author}</h2>
            <div className="opinion-page__description">{description}</div>
          </div>
        </main>

        <div className="opinion-page__others">
          <h2>{t("Więcej opinii")}</h2>
          <div className="other-opinions-list">
            {other_opinions.map((item) => (
              <Opinion
                user={item.opinion.opinionAuthor}
                origin={item.opinion.opinionOrigin}
                contents={item.opinion.opinionDescription}
                variant="card"
                uri={item.uri}
                color="black"
                rate={item.opinion.opinionRate}
                key={item.opinion.opinionAuthor}
              />
            ))}
          </div>
          <Button href={t("opinions_link")}>{t("Pokaż więcej opinii")}</Button>
        </div>
      </div>
    </div>
  );
}

export default OpinionPage;

export const query = graphql`
  query ($title: String!) {
    wpOpinion(title: { eq: $title }) {
      opinion {
        opinionRate
        opinionOrigin
        opinionDescription
        opinionAuthor
        opinionScreen {
          gatsbyImage(quality: 100, width: 479, layout: FULL_WIDTH)
        }
      }
    }

    other_opinions: allWpOpinion(limit: 4) {
      nodes {
        uri
        opinion {
          opinionOrigin
          opinionDescription
          opinionAuthor
          opinionRate
        }
      }
    }
  }
`;
